import { Accordion, Button } from "react-bootstrap";

import { useState, useEffect, useContext } from "react";

import "./DetailsHeader.css";
import { returnStatusName } from "../../../utils/utils";
import { UserContext } from "../../contexts/UserContex";
import { useParams } from "react-router-dom";
import directionLogo from "../../../assets/GoogleMaps.png";

import axios from "axios";
import serverUrl from "../../../config";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DetailsHeader = ({
  completedWork,
  response,
  canChangeStatusHandler,
  submitFormHandler,
}) => {
  const [isAwaitingAcceptance, setIsAwaitingAcceptance] = useState(false);

  const { userData } = useContext(UserContext);

  const { id } = useParams();

  const GoogleMapsHandler = () => {
    const location = response?.ticket?.ticketPMSPlaceLocation;

    // const location = "гр. София, ул Майор Горталов 5";
    if (location) {
      const googleMapsUrl = `https://www.google.com/maps/dir//${encodeURIComponent(
        location
      )}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

  const PhoneDealHandler = () => {
    window.location.href = `tel:${response?.ticket?.ticketPMSContactPhone}`;
  };

  useEffect(() => {
    switch (response?.ticket?.ticketStatus) {
      case 1:
        setIsAwaitingAcceptance(true);
        canChangeStatusHandler(false);
        break;
      case 2:
        setIsAwaitingAcceptance(false);
        canChangeStatusHandler(true);
        break;
      case 3:
        setIsAwaitingAcceptance(false);
        canChangeStatusHandler(true);
        break;
      case (11, 12, 13):
        setIsAwaitingAcceptance(false);
        canChangeStatusHandler(false);
        break;
      default:
        setIsAwaitingAcceptance(false);
        canChangeStatusHandler(false);
        break;
    }
  }, [response?.ticket?.ticketStatus, canChangeStatusHandler]);

  const changeTicketStatusAcceptHandler = (newStatus) => {
    let confirmMessage = "";
    // let newTicketWorkerStatus = 0;

    switch (newStatus) {
      case 2:
        confirmMessage =
          "Сигурни ли сте, че искате да приемете да работите по този тикет?";

        // newTicketWorkerStatus = 0;
        break;
      case 13:
        confirmMessage =
          "Сигурни ли сте, че искате да откажете да работите по този тикет?";
        // newTicketWorkerStatus = 0;
        break;
      default:
        confirmMessage = "Възникна грешка.";
        // newTicketWorkerStatus = 0;
        break;
    }

    confirmAlert({
      title: "Потвърдете",
      message: confirmMessage,
      buttons: [
        {
          label: "Да",
          onClick: async () => {
            const requestData = {
              nUser: userData.userId,
              nUserTicket: id,
              nNewStatus: newStatus,
            };

            JSON.stringify(requestData);
            const data = requestData;

            const headers = {
              "Content-Type": "text/plain; charset=utf-8",
              Authorization: `Bearer ${userData.userAccToken}`,
            };

            try {
              const response = await axios.post(
                serverUrl + "/ticket/changeStatus",
                data,
                {
                  headers,
                }
              );

              console.log(response.data);
              submitFormHandler(true);

              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: "Не",
          onClick: () => {
            // Handle the rejection or perform any other action
            console.log("Form submission canceled");
          },
        },
      ],
    });
  };

  return (
    <div className="ticket-details log-in-form">
      <p>Описание на дейността:</p>
      <h2>{response?.ticket?.ticketTask}</h2>
      <p>
        <strong>Контрагент:</strong> {response?.ticket?.contragent}
      </p>
      <p>
        <strong>БУЛСТАТ:</strong> {response?.ticket?.eik}
      </p>
      <p>
        <strong>Начална дата:</strong> {response?.ticket?.ticketBegDate}
      </p>
      <p>
        <strong>Крайна дата:</strong> {response?.ticket?.ticketEndDate}
      </p>
      <p>
        <strong>Планирани часове: </strong>
        {response?.ticket?.ticketPMSPlannedHours} часа
      </p>
      <p>
        <strong>Дейност: </strong>
        {response?.ticket?.ticketPMSTaskName}
      </p>

      <hr></hr>
      <p>
        <strong>Търговец, отговорен за сделката: </strong>
        {response?.ticket?.ticketPMSTrader}
      </p>

      <p>
        <strong>Обект: </strong>
        {response?.ticket?.ticketPMSObject}
      </p>

      <p>
        <strong>Лице за контант на обекта: </strong>
        {response?.ticket?.ticketPMSContactPerson}
      </p>

      <p>
        <strong>Телефон на лицето за контакт: </strong>
        <span className="phone-deal" onClick={PhoneDealHandler}>
          {response?.ticket?.ticketPMSContactPhone}
        </span>
      </p>

      <p>
        <strong>Местоположение: </strong>
        {response?.ticket?.ticketPMSPlaceLocation}
        <img
          className="direction-logo"
          src={directionLogo}
          alt="google-map-icon"
          onClick={GoogleMapsHandler}
        />
      </p>
      {/* <p>
        <img
          className="direction-logo"
          src="https://1000logos.net/wp-content/uploads/2021/05/Google-Maps-logo.png"
          height="70px"
          alt="google-map-icon"
          onClick={GoogleMapsHandler} // Call the function when clicked
          style={{ cursor: "pointer" }} // Change cursor to indicate clickability
        />
      </p> */}
      <hr></hr>
      <p>
        <strong>Отговорник: </strong>
        {response?.ticket?.ticketTaskLeader}
      </p>
      <p>
        <strong>Екип:</strong>
        {response?.ticket?.ticketTeam}
      </p>
      <hr></hr>
      <p>
        <strong>Статус: </strong>{" "}
        {returnStatusName(response?.ticket?.ticketStatus)}
      </p>

      {response?.ticket?.ticketStatus === 11 ||
      response?.ticket?.ticketStatus === 12 ? (
        <p>
          <strong>От: </strong> {response?.ticket?.ticketStatusFinishedFrom}
        </p>
      ) : null}

      <hr></hr>

      {isAwaitingAcceptance ? (
        <div className="accept-decline-ticket-wrapper">
          <Button
            onClick={() => changeTicketStatusAcceptHandler(2)}
            variant="success"
          >
            Приеми за изпълнение
          </Button>
          <Button
            onClick={() => changeTicketStatusAcceptHandler(13)}
            variant="danger"
          >
            Откажи
          </Button>
        </div>
      ) : (
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <p>Покажи извършената работа до момента</p>
            </Accordion.Header>
            <Accordion.Body> {completedWork}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </div>
  );
};

export default DetailsHeader;
