import "./Ticket.css";
import { Link } from "react-router-dom";
import { Fragment } from "react";

import { returnStatusName } from "../../utils/utils";

const Ticket = ({ ticketData }) => {
  let ticketStyle = "";

  switch (ticketData.ticketStatus) {
    case 1: // Очаква приемане
      ticketStyle = "ticket ticket-await-accept";
      break;

    case 2: // Приет за изпълнение
      ticketStyle = "ticket ticket-active";
      break;

    case 3: // Изпълнен - За одобрение
      ticketStyle = "ticket ticket-revision";
      break;

    case 11: // Приключен тикет
      ticketStyle = "ticket  ticket-finished";
      break;

    case 12: // Прекратен тикет
      ticketStyle = "ticket ticket-terminated";
      break;

    case 13: // Отказан за изпълнение
      ticketStyle = "ticket ticket-terminated";

      break;

    default:
      break;
  }

  return (
    <div className={ticketStyle}>
      <h2>{ticketData.ticketTask}</h2>
      <hr></hr>
      <p>
        <strong>Статус: </strong>
        {returnStatusName(ticketData.ticketStatus)}
      </p>
      <hr></hr>
      <p>
        <strong>Контрагент: </strong>
        {ticketData.contragent}
      </p>

      <p>
        <strong>Начална дата: </strong> {ticketData.ticketBegDate}
      </p>
      <p>
        <strong>Краен срок: </strong> {ticketData.ticketEndDate}
      </p>
      <p>
        <strong>Планирани часове: </strong>
        {ticketData.ticketPMSPlannedHours} часа
      </p>

      {ticketData.ticketHelpersAll > 0 ? (
        <Fragment>
          <hr></hr>
          <p>
            <strong>Помощници: </strong>
            {ticketData.ticketHelpersAll}
          </p>
        </Fragment>
      ) : null}

      {/* {ticketData.ticketHelpersWorking > 0 ? (
        <p>
          В момента работят: <strong>{ticketData.ticketHelpersWorking}</strong>
        </p>
      ) : null} */}

      {ticketData.ticketHelpersRevision > 0 ? (
        <p>
          Чакащи потвърждение:{" "}
          <strong>{ticketData.ticketHelpersRevision}</strong>
        </p>
      ) : null}

      <Link
        key={ticketData.cobjTicket}
        to={"/tickets/" + ticketData.cobjTicket}
      >
        <button className="log-button btn btn-primary ticket-summary-button">
          Подробно
        </button>
      </Link>
    </div>
  );
};

export default Ticket;
