import "./UserProfile.css";
import { useContext, useCallback } from "react";
import { UserContext } from "../contexts/UserContex";

let logoutTimer;

const UserProfile = () => {
  const { userData, logOutHandler } = useContext(UserContext);

  const handleLogOut = useCallback(() => {
    logOutHandler();
    clearTimeout(logoutTimer);
    window.location.href = "/";
  }, [logOutHandler]);

  return (
    <div className="user-profile">
      <p>
        Име:
        <strong> {userData.name}</strong>
      </p>
      <hr></hr>
      <div className="user-data">
        <button className="btn btn-primary " onClick={handleLogOut}>
          Изход
        </button>
      </div>
      <hr></hr>
      <p>Изработено планувано време: </p>
      <p>1. От начало на год.: </p>
      <p>2. От начало на месец: </p>
      <p>Бъдещо планувано време прието за изпълнение: </p>
    </div>
  );
};

export default UserProfile;
