import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import LogInForm from "./components/log-in/LogInForm";
import Home from "./pages/Home";
// BrowserRouter
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "./components/contexts/UserContex";

import { useEffect, useCallback } from "react";

import TicketDetails from "./components/tickets/TicketDetails";
import MainMenu from "./components/navigation/Navigation";
import UserPage from "./pages/UserPage";

let logoutTimer;

function App() {
  const { userData, logOutHandler } = useContext(UserContext);

  const handleLogOut = useCallback(() => {
    logOutHandler();
    clearTimeout(logoutTimer);
    window.location.href = "/";
  }, [logOutHandler]);

  // AutoLogout - само че е малко бъгав и трябва да се рефрешне страницата за да тръгне.
  // В момента показва в конзолата, колко време остава от сесията при рефреш, може да се направи
  // на таймер и през 1 секунда да се показва някъде в сайта, че сесията ще приключи, след .... време.
  // може би трябва да отиде в App.js, да е на по предно ниво.
  useEffect(() => {
    if (userData?.isLogged) {
      if (userData?.remainingTime > 0) {
        const sec = Math.floor((userData.remainingTime / 1000) % 60)
          .toFixed(0)
          .padStart(2, "0");
        const mins = Math.floor(userData.remainingTime / 1000 / 60)
          .toFixed(0)
          .padStart(2, "0");
        const hours = Math.floor(userData.remainingTime / 1000 / 60 / 60)
          .toFixed(0)
          .padStart(2, "0");

        console.log(`Auto Logout in: ${hours}:${mins}:${sec}`);
        logoutTimer = setTimeout(() => {
          handleLogOut();
        }, userData?.remainingTime);
      }
    } else {
      if (logoutTimer) {
        console.log(`I logged Out. Timer is removed`);
        clearTimeout(logoutTimer);
      }
    }
  }, [userData.isLogged, userData.remainingTime, handleLogOut]);

  return (
    <Router>
      {userData.isLogged ? <MainMenu></MainMenu> : null}
      <div className="app-wrapper">
        {userData.isLogged ? (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/userProfile" element={<UserPage />} />
            <Route path="/tickets/:id" element={<TicketDetails />} />
          </Routes>
        ) : (
          <LogInForm />
        )}
      </div>
    </Router>
  );
}

export default App;
