import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

import { Link } from "react-router-dom";
import { useRef } from "react";

import "./Navigation.css";

function MainMenu() {
  const offCanvasRef = useRef();
  //   const offsetValue = -56;
  const closeOffCanvas = () => {
    offCanvasRef.current.backdrop?.click();
  };

  return (
    <>
      {["xxl"].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Brand as={Link} to="">
              <img
                src="https://assets.jobs.bg/assets/logo/2018-12-12/b_8ef1fa87a56fcf3c9e469a7b057f8711.jpg"
                alt="nola7"
                width={100}
              ></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              ref={offCanvasRef}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Меню
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link
                    as={Link}
                    to=""
                    onClick={closeOffCanvas}
                    className="mobile-menu-item"
                  >
                    Работа с тикети
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="userProfile"
                    onClick={closeOffCanvas}
                    className="mobile-menu-item"
                  >
                    Потребителски профил
                  </Nav.Link>
                  {/* <Button
                    onClick={closeOffCanvas}
                    className="mobile-menu-button"
                  >
                    Изход
                  </Button> */}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default MainMenu;
