import { Button } from "react-bootstrap";

import "./DetailsChangeTicketStatus.css";
import axios from "axios";
import serverUrl from "../../../config";
import { UserContext } from "../../contexts/UserContex";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DetailsChangeTicketStatus = ({ response, submitFormHandler }) => {
  let buttonYes = "";
  let buttonNo = "";
  let buttonStatusYes = 0;
  let buttonStatusNo = 0;

  let buttonFlag = false;

  const { userData } = useContext(UserContext);

  const { id } = useParams();

  const handleButtonClick = (newStatus) => {
    let confirmText = "";

    switch (newStatus) {
      case 3:
        confirmText =
          "Сигурни ли сте, че искате да ПРЕДАДЕТЕ тикета за проверка. След предаването му няма да може да извършвате повече работа по него.";
        break;
      case 11:
        confirmText =
          "Сигурни ли сте, че искате да ПРИКЛЮЧИТЕ тикета. След приключването му няма да може да извършвате повече работа по него.";
        break;
      case 13:
        confirmText =
          "Сигурни ли сте, че искате да ОТКАЖЕТЕ тикета. След отказването му няма да може да извършвате повече работа по него.";
        break;

      default:
        break;
    }

    confirmAlert({
      title: "Потвърдете",
      message: confirmText,
      buttons: [
        {
          label: "Да",
          onClick: async () => {
            const requestData = {
              nUser: userData.userId,
              nUserTicket: id,
              nNewStatus: newStatus,
            };

            JSON.stringify(requestData);
            const data = requestData;

            const headers = {
              "Content-Type": "text/plain; charset=utf-8",
              Authorization: `Bearer ${userData.userAccToken}`,
            };

            try {
              const response = await axios.post(
                serverUrl + "/ticket/changeStatus",
                data,
                {
                  headers,
                }
              );

              console.log(response.data);
              submitFormHandler(true);

              if (response.data.error !== "") {
                toast.error(response.data.error, {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                toast.success(response.data.message, {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            } catch (error) {
              console.log(response.data.error);
            }
          },
        },
        {
          label: "Не",
          onClick: () => {
            // Handle the rejection or perform any other action
            console.log("Form submission canceled");
          },
        },
      ],
    });
  };

  if (response?.ticket?.ticketStatus === 3) {
    buttonFlag = true;
  } else {
    buttonFlag = false;
  }

  if (response?.ticket?.ticketLevel === 1) {
    buttonYes = "Приключи";
    buttonNo = "Откажи за изпълнение";
    buttonStatusYes = 11;
    buttonStatusNo = 13;
  } else {
    buttonYes = "Изпълнен - за одобрение";
    buttonNo = "Откажи за изпълнение";
    buttonStatusYes = 3;
    buttonStatusNo = 13;
  }

  return (
    <div className="log-in-form ticket-details">
      <p>Смяна на статус на тикета:</p>
      {buttonFlag ? (
        <Button
          onClick={() => handleButtonClick(2)}
          className="buttom-change-status"
        >
          Върни за изпълнение
        </Button>
      ) : (
        <>
          <Button
            className="buttom-change-status"
            variant="success"
            onClick={() => handleButtonClick(buttonStatusYes)}
          >
            {buttonYes}
          </Button>
          <Button
            className="buttom-change-status"
            variant="danger"
            onClick={() => handleButtonClick(buttonStatusNo)}
          >
            {buttonNo}
          </Button>
        </>
      )}
    </div>
  );
};

export default DetailsChangeTicketStatus;
